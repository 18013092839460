import React from 'react';
import { View, Text, Image } from 'react-native';
import { MaterialCommunityIcons ,Entypo,Octicons} from '@expo/vector-icons';
const StatsCard = () => {
    return (
        <>
            <View style={{
                paddingHorizontal: 8,
                width: '25%'
            }}>
                <View style={{
                    backgroundColor: '#1982ec',
                    borderRadius: 10,
                    padding: 20,
                    alignItems: 'start',
                }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 10,
                    }}>
                        <View style={{
                            backgroundColor: 'white',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            border:'1px solid #746f6f',
                            marginRight: 6,
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }} >
                            <MaterialCommunityIcons name="cash" size={22} color="#42b2f0" />
                        </View>
                        <Text style={{
                            color: '#fff',
                            fontSize: 16,
                            fontWeight: 'bold',
                        }}>Total Sales</Text>
                    </View>
                    <Text style={{
                        color: '#fff',
                        fontSize: 26,
                        // fontWeight: 'bold',
                        marginBottom: 10,
                    }}>$12,521.00</Text>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <Text style={{
                            color: '#fff',
                            fontSize: 16,
                        }}>+10%</Text>
                        <Text style={{
                            color: '#fff',
                            fontSize: 16,
                        }}>+$1.2k Today</Text>
                    </View>
                </View>
            </View>
            <View style={{
                paddingHorizontal: 8,
                width: '25%'
            }}>
                <View style={{
                    backgroundColor: 'white',
                    borderRadius: 10,
                    border:'1px solid #e2e2e2',
                    padding: 20,
                    alignItems: 'start',
                }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 10,
                    }}>
                        <View style={{
                            backgroundColor: 'white',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            border:'1px solid #746f6f',
                            marginRight: 6,
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }} >
                            <Entypo name="bar-graph" size={22} color="#42b2f0" />
                        </View>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                            fontWeight: 'bold',
                        }}>Invest</Text>
                    </View>
                    <Text style={{
                        color: '#000',
                        fontSize: 26,
                        // fontWeight: 'bold',
                        marginBottom: 10,
                    }}>$12,521.00</Text>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                        }}>+10%</Text>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                        }}>+$1.2k Today</Text>
                    </View>
                </View>
            </View>
            <View style={{
                paddingHorizontal: 8,
                width: '25%'
            }}>
                <View style={{
                    backgroundColor: 'white',
                    borderRadius: 10,
                    border:'1px solid #e2e2e2',
                    padding: 20,
                    alignItems: 'start',
                }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 10,
                    }}>
                        <View style={{
                            backgroundColor: 'white',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            border:'1px solid #746f6f',
                            marginRight: 6,
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }} >
                            <Entypo name="line-graph" size={22} color="#42b2f0" />
                        </View>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                            fontWeight: 'bold',
                        }}>Cash Outflow</Text>
                    </View>
                    <Text style={{
                        color: '#000',
                        fontSize: 26,
                        // fontWeight: 'bold',
                        marginBottom: 10,
                    }}>$12,521.00</Text>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                        }}>+10%</Text>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                        }}>+$1.2k Today</Text>
                    </View>
                </View>
            </View>
            <View style={{
                paddingHorizontal: 8,
                width: '25%'
            }}>
                <View style={{
                    backgroundColor: 'white',
                    borderRadius: 10,
                    border:'1px solid #e2e2e2',
                    padding: 20,
                    alignItems: 'start',
                }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 10,
                    }}>
                        <View style={{
                            backgroundColor: 'white',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            border:'1px solid #746f6f',
                            marginRight: 6,
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }} >
                            <Octicons name="graph" size={22} color="#42b2f0" />
                        </View>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                            fontWeight: 'bold',
                        }}>Current Stock</Text>
                    </View>
                    <Text style={{
                        color: '#000',
                        fontSize: 26,
                        // fontWeight: 'bold',
                        marginBottom: 10,
                    }}>$12,521.00</Text>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                        }}>+10%</Text>
                        <Text style={{
                            color: '#746f6f',
                            fontSize: 16,
                        }}>+$1.2k Today</Text>
                    </View>
                </View>
            </View>
        </>
    );
};



export default StatsCard;