import { useNavigation, useNavigationState } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { TouchableOpacity, StyleSheet, Platform, Dimensions } from 'react-native';
import { Colors, View, Text, ExpandableSection, Button, Image } from 'react-native-ui-lib';
import { Link, useNavigate } from 'react-router-dom';
import { useStores } from '../mycomponents/state';
import { observer } from 'mobx-react-lite';
import { Icon } from 'react-native-eva-icons';
import { api } from '../services/api';
import { GlobalMasterStore } from '../mycomponents/state/PermissionStore';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ScrollView } from 'react-native-gesture-handler';
import { StatusBar } from 'react-native';
import { getCustomSidebarMenu } from './custom_sidebars';

const ProjectsComponent = (props) => {
    const { modalStore: { setOpenSomething, getOpenSomething, getModalHistory }, layoutStore: { isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition, closeSideBar } } = useStores();
    const nativeRoute = useNavigationState(state => state);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const [projects, setProjects] = React.useState([]);
    const [expandedItem, setExpandedItem] = React.useState(null);

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
        closeSideBar();
    };

    const getProjects = async () => {
        const { data } = await api.get("Projects", {
            page: 1,
            limit: 100,
            sortColumn: 'name',
            sortDirection: 'asc',
            filters: [],
            tabFilter: [],
            search: '',
            groupBy: null,
        });

        setProjects(data?.data || []);
    }

    useEffect(() => {
        getProjects();
    }, []);

    return <View flex centerV>
        {/* {projects.map((project, index) => <ExpandableSection
            key={index.toString()}
            expanded={expandedItem == type + index}
            onPress={() => {
                if (expandedItem == type + index) {
                    setExpandedItem(null);
                } else {
                    setExpandedItem(type + index);
                }
            }}
            sectionHeader={<View flex centerV marginB-5>
                <TouchableOpacity style={[styles.MenuHead, {}, isSideBarCollapsed ? { marginRight: 5 } : {}]} onPress={() => {
                    if (expandedItem == type + index) {
                        setExpandedItem(null);
                    } else {
                        setExpandedItem(type + index);
                    }
                }}>
                    <View style={{ width: 20, height: 20, backgroundColor: '#e6f4ff', borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}>
                        <Text darkCardTitle style={{ fontSize: 9, color: '#2783cc', letterSpacing: 0.6 }}>
                            {project.name.substring(0, 2).toUpperCase()}
                        </Text>
                    </View>
                    {!isSideBarCollapsed && <Text style={[styles.MenuText]}>{project.name}</Text>}
                </TouchableOpacity>
            </View>}
        >
            {project.lists.map((list, listIndex) => {
                return <TouchableOpacity
                    onPress={() => {
                        navigationFn("Tasks", {
                            project_id: project._id,
                            list_id: list._id,
                            is_default_values: true,
                        });
                    }}
                    key={listIndex.toString()} style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 40 }}>

                    <Text style={{ flex: 1 }}>{list.name}</Text>
                </TouchableOpacity>
            })}
        </ExpandableSection>)} */}
    </View>;
};

export const Sidebar = observer(function Sidebar() {
    const {
        modalStore: {
            setOpenSomething, getOpenSomething, getModalHistory
        },
        layoutStore: {
            isSideBarCollapsed, toggleSideBarCollapsed, getSideBarPosition, setSideBarPosition, getCurrentTab, setCurrentTab, closeSideBar
        }
    } = useStores();

    const permissions = GlobalMasterStore((state) => state.permissions);
    const setToken = GlobalMasterStore((state) => state.setToken);
    const employee = GlobalMasterStore((state) => state.employee);
    const organization_id = GlobalMasterStore((state) => state.globalData.organization_id);

    const [expandedItem, setExpandedItem] = React.useState(null);
    const [showGYM, setShowGYM] = React.useState(false);

    // const [showHRMS, setShowHRMS] = React.useState(getCurrentTab === 'HRMS');
    // const [showAccounting, setShowAccounting] = React.useState(getCurrentTab === 'Accounting');
    // const [showTasks, setShowTasks] = React.useState(getCurrentTab === 'Tasks');
    // const [showMasters, setShowMasters] = React.useState(getCurrentTab === 'Masters');
    // const [showApprovals, setShowApprovals] = React.useState(getCurrentTab === 'Approvals');

    const [showHRMS, setShowHRMS] = React.useState(true);
    const [showAccounting, setShowAccounting] = React.useState(true);
    const [showTasks, setShowTasks] = React.useState(true);
    const [showMasters, setShowMasters] = React.useState(true);
    const [showApprovals, setShowApprovals] = React.useState(true);

    const [hrmsMenu, setHrmsMenu] = React.useState([]);
    const [accountingMenu, setAccountingMenu] = React.useState([]);
    const [taskMenu, setTaskMenu] = React.useState([]);
    const [masterMenu, setMasterMenu] = React.useState([]);
    const [approvalsMenu, setApprovalsMenu] = React.useState([]);

    const [customSidebarMenu, setCustomSidebarMenu] = React.useState([]);

    const updateMenu = () => {
        const hrmsMenuItems = [
            {
                "label": "Employees", "icon": "Employees", "children": [
                    { "label": "Employees", "icon": "Employees", "link": "Employees", "children": null },
                    { "label": "Shifts", "icon": "Shifts", "link": "Shifts", "children": null },
                    // { "label": "ShiftSchedulers", "icon": "ShiftSchedulers", "link": "ShiftSchedulers", "children": null },
                ]
            },
            {
                "label": "Attendance", "icon": "Leave", "children": [
                    { "label": "By Date", "link": "report/AttendanceByDate", "children": null },
                    { "label": "By Employee", "link": "report/AttendanceByEmployee", "children": null },
                ]
            },
            {
                "label": "Leave", "icon": "Leave", "children": [
                    { "label": "Leave Requests", "icon": "LeaveRequests", "link": "LeaveRequests", "children": null },
                    { "label": "Leave Categories", "icon": "LeaveCategories", "link": "LeaveCategories", "children": null },
                ]
            },
            { "label": "Holidays", "icon": "Holidays", "link": "Holidays", "children": null },
            {
                "label": "Recruitement", "icon": "interview", "children": [
                    { "label": "Jobs", "icon": "Jobs", "link": "Jobs", "children": null },
                    { "label": "JobApplicants", "icon": "JobApplicants", "link": "JobApplicants", "children": null },
                ]
            },
            {
                "label": "Reimbursement", "icon": "Payrolls", "children": [
                    { "label": "Advances", "link": "ReimbursementAdvances", "children": null },
                    { "label": "Claims", "link": "ReimbursementClaims", "children": null },
                    { "label": "Claim Categories", "link": "ReimbursementCategories", "children": null },
                ]
            },
            {
                "label": "Payroll", "icon": "Payrolls", "children": [
                    { "label": "Payrolls", "icon": "Payrolls", "link": "Payrolls", "children": null },
                    { "label": "PayrollSalaryItems", "icon": "PayrollSalaryItems", "link": "PayrollSalaryItems", "children": null },
                    { "label": "PayrollStatutoryComponents", "icon": "PayrollStatutoryComponents", "link": "PayrollStatutoryComponents", "children": null },
                ]
            },
            {
                "label": "Training", "icon": "Training", "link": "Courses", "children": null
            }
            // {
            //     "label": "Masters", "icon": "Master", "children": [
            //         { "label": "Departments", "icon": "Departments", "link": "Departments", "children": null },
            //         { "label": "Designations", "icon": "Designations", "link": "Designations", "children": null },
            //         { "label": "Locations", "icon": "WorkLocations", "link": "Locations", "children": null },
            //         { "label": "Teams", "icon": "Teams", "link": "Teams", "children": null },
            //         { "label": "Roles", "icon": "Roles", "link": "Roles", "children": null },
            //     ]
            // },
        ];

        const accountingMenuItems = [
            {
                "label": "Finance", "icon": "Payrolls",
                "children": [
                    { "label": "Financial Summary", "link": "report/FinancialSummary", "children": null },
                    { "label": "Money Transfers", "icon": "MoneyTransfers", "link": "MoneyTransfers", "children": null },
                    { "label": "Payment Received", "icon": "PaymentReceived", "link": "PaymentReceived", "children": null },
                    { "label": "Payment Made", "icon": "PaymentMade", "link": "PaymentMade", "children": null },
                ]
            },
            {
                "label": "Items", "icon": "Items",
                "children": [
                    { "label": "Items", "icon": "items", "link": "Items", },
                    // { "label": "Group Items", "icon": "items", "link": "ItemGroups" },
                    // { "label": "Item Adjustments", "icon": "ItemAdjustments", "link": "ItemAdjustments", "children": null },
                    { "label": "Item Transfers", "icon": "Transfers", "link": "Transfers", "children": null },
                    // { "label": "Item Traceability", "icon": "Transfers", "link": "Transfers", "children": null },
                    { "label": "Item Tracing", "icon": "Transfers", "link": "ItemTracing", "children": null },
                    { "label": "Print Barcode", "icon": "BarcodePrinting", "link": "BarcodePrinting", "children": null },
                ]
            }, {
                "label": "Contacts", "icon": "3d-contact",
                "children": [
                    { "label": "Contacts", "icon": "contacts", "link": "Contacts", },
                    { "label": "Appointments", "icon": "contacts", "link": "Appointments", },
                    { "label": "Subscriptions", "icon": "contacts", "link": "Subscriptions", },
                    { "label": "Price Lists", "icon": "PriceLists", "link": "PriceLists" }
                ]
            },
            {
                "label": "Sales", "icon": "3d-Sales",
                "children": [
                    { "label": "Table Managment", "icon": "POS", "link": "table", "children": null },
                    { "label": "Quick POS", "icon": "POS", "link": "POS", "children": null },
                    { "label": "Inquiries", "icon": "Inquiries", "link": "Inquiries", "children": null },
                    { "label": "Quotations", "icon": "Quotations", "link": "Quotations", "children": null },
                    { "label": "Sales Orders", "icon": "SalesOrders", "link": "SalesOrders", "children": null },
                    { "label": "Delivery Orders", "icon": "DeliveryOrders", "link": "DeliveryOrders", "children": null },
                    { "label": "Sales Invoices", "icon": "Invoices", "link": "Invoices", "children": null },
                    { "label": "Credit Notes", "icon": "SalesReturns", "link": "SalesReturns", "children": null },
                    // { "label": "Counter Master", "icon": "Counters", "link": "Counters", "children": null },
                ]
            },
            {
                "label": "Purchases", "icon": "3d-Purchase",
                "children": [
                    { "label": "Purchase Orders", "icon": "PurchaseOrders", "link": "PurchaseOrders", "children": null },
                    { "label": "Purchase Bills", "icon": "Bills", "link": "Bills", "children": null },
                    { "label": "Debit Notes", "icon": "PurchaseReturns", "link": "PurchaseReturns", "children": null },
                ]
            },
            {
                "label": "Accounting", "icon": "accounts",
                "children": [
                    { "label": "Accounts", "icon": "Accounts", "link": "Accounts", "children": null },
                    { "label": "Manual Journals", "icon": "ManualJournals", "link": "ManualJournals", "children": null },
                    { "label": "Tcs", "icon": "Tcs", "link": "Tcs", "children": null },
                    { "label": "Taxes", "icon": "Taxes", "link": "Taxes", "children": null },
                    { "label": "TaxGroups", "icon": "TaxGroups", "link": "TaxGroups", "children": null },
                ]
            },
            { "label": "Expenses", "icon": "no-money", "link": "Expenses", "children": null },
            { "label": "Reports", "icon": "Reports", "link": "AccountingReports", "children": null },
        ]

        const taskMenuItems = [
            { "label": "Manage Projects", "icon": "ProjectList", "link": "Projects", "children": null },
        ];

        const masterMenuItems = [
            { "label": "Positions", "link": "Positions", "children": null },
            { "label": "Departments", "link": "Departments", "children": null },
            { "label": "Designations", "link": "Designations", "children": null },
            { "label": "Locations", "link": "Locations", "children": null },
            { "label": "Teams", "link": "Teams", "children": null },
            { "label": "Roles and Permissions", "link": "Roles", "children": null },
        ]

        const approvalsMenuItems = [
            // { "label": "Approvals Requests", "icon": "", "link": "ApprovalRequests", "children": null },
            { "label": "Approval Rules", "icon": "", "link": "ApprovalRules", "children": null },
            { "label": "Approval Work Flow", "icon": "", "link": "ApprovalWorkflows", "children": null },
        ]

        const customMenuItems = getCustomSidebarMenu(organization_id) || [];

        function filterBasedOnPermissions(menuItems) {
            return menuItems.filter(item => {
                if (item.children) {
                    item.children = filterBasedOnPermissions(item.children);
                    return item.children.length;
                } else {
                    return permissions[item.link] && permissions[item.link].view;
                    // return true;
                }
            });
        }

        const filteredHrmsMenuItems = filterBasedOnPermissions(hrmsMenuItems);
        setHrmsMenu(filteredHrmsMenuItems);

        const filteredAccountingMenuItems = filterBasedOnPermissions(accountingMenuItems);
        setAccountingMenu(filteredAccountingMenuItems);

        const filteredTaskMenuItems = filterBasedOnPermissions(taskMenuItems);
        setTaskMenu(filteredTaskMenuItems);

        const filteredMasterMenuItems = filterBasedOnPermissions(masterMenuItems);
        setMasterMenu(filteredMasterMenuItems);

        const filteredApprovalsMenuItems = filterBasedOnPermissions(approvalsMenuItems);
        setApprovalsMenu(filteredApprovalsMenuItems);

        const filteredCustomMenuItems = filterBasedOnPermissions(customMenuItems);
        setCustomSidebarMenu(filteredCustomMenuItems);
    }

    useEffect(() => {
        updateMenu();
    }, [permissions]);

    const gymMenu = [
        {
            "label": "Products", "icon": "Items",
            "children": [
                { "label": "Products", "icon": "items", "link": "Items", },
                // { "label": "Group Items", "icon": "items", "link": "ItemGroups" },
                { "label": "Stock Adjustments", "icon": "ItemAdjustments", "link": "ItemAdjustments", "children": null },
                { "label": "Stock Transfers", "icon": "Transfers", "link": "Transfers", "children": null },
                { "label": "Locations", "icon": "Locations", "link": "Locations", "children": null },
                { "label": "Categories", "icon": "categories", "link": "Categories", "params": { "is_default_values": true, "type": "item" } },
            ]
        },
        {
            "label": "Plans", "icon": "gymplans", "link": "Plans",
        },
        {
            "label": "Members", "icon": "3d-contact",
            "children": [
                { "label": "Members", "icon": "contacts", "link": "Contacts", },
                { "label": "Categories", "icon": "categories", "link": "Categories", "params": { "is_default_values": true, "type": "contact" } }
            ]
        },
        { "label": "Workout Plans", "icon": "gym", "link": "WorkoutPlans", "children": null },
        { "label": "Diet Plans", "icon": "DietPlan", "link": "DietPlans", "children": null },
        { "label": "Classes", "icon": "yoga", "link": "GymClasses", "children": null },
        { "label": "Equipments", "icon": "bike", "link": "Equipments", "children": null },
        { "label": "Exercises", "icon": "dumbell", "link": "Exercises", "children": null },
    ];

    const nativeRoute = useNavigationState(state => state);

    let navigation = useNavigation();
    let navigate = null;

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
            closeSideBar();
        }
    };

    useEffect(() => {
        if (Platform.OS === 'web') {
            let path = window?.location?.pathname;
            if (path) {
                path = path.replace("/", "");

                let item = approvalsMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Approvals" + hrmsMenu.indexOf(item));
                }

                item = hrmsMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("HRMS" + hrmsMenu.indexOf(item));
                }

                item = accountingMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Accounting" + accountingMenu.indexOf(item));
                }

                item = taskMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Projects & Tasks" + taskMenu.indexOf(item));
                }

                item = masterMenu.find(item => item.link === path);

                if (item) {
                    setExpandedItem("Company Master" + masterMenu.indexOf(item));
                }
            }
        } else {
            let path = nativeRoute?.routes?.[0]?.state?.routes?.[0]?.state?.routes?.[0]?.state?.routes?.[0]?.name;
            if (path) {
                path = path.replace("/", "");
                if (path) {
                    let item = hrmsMenu.find(item => item.link === path);
                    if (item) {
                        setExpandedItem(hrmsMenu.indexOf(item));
                    }
                }
            }
        }
    }, [nativeRoute, window?.location?.pathname]);

    const renderMenu = (menu, type) => {
        return menu.map((item, index) => {
            return <ExpandableSection
                key={index.toString()}
                expanded={expandedItem == type + index}
                onPress={() => {
                    if (expandedItem == type + index) {
                        setExpandedItem(null);
                    } else {
                        setExpandedItem(type + index);
                        if (item.link) {
                            navigationFn(item.link, {});
                        }
                    }
                }}
                sectionHeader={<View flex centerV marginB-0>
                    {!item.link ?
                        <View style={[styles.MenuHead, expandedItem == type + index ? styles.highlightMenuItem : {}]}>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/${item.icon}.png` }} style={{ width: 20, height: 20 }} />
                            {!isSideBarCollapsed && <Text style={[styles.MenuText]}>{item.label}</Text>}
                        </View> :
                        Platform.OS === 'web' ? <Link to={item.link} >
                            <View style={[styles.MenuHead, expandedItem == type + index ? styles.highlightMenuItem : {}, isSideBarCollapsed ? { marginRight: 5 } : {}]}>
                                {item.icon ? <Image source={{ uri: `https://inkapps.pages.dev/icons/${item.icon}.png` }} style={{ width: 20, height: 20 }} /> : item.iconLink ? <Image source={{ uri: item.iconLink }} style={{ width: 20, height: 20 }} /> : <></>}
                                {!isSideBarCollapsed && <Text style={[styles.MenuText]}>{item.label}</Text>}
                            </View>
                        </Link> :
                            <TouchableOpacity style={[styles.MenuHead, expandedItem == type + index ? styles.highlightMenuItem : {}, isSideBarCollapsed ? { marginRight: 5 } : {}]} onPress={() => {
                                navigationFn(item.link, {});
                            }}>
                                {item.icon ? <Image source={{ uri: `https://inkapps.pages.dev/icons/${item.icon}.png` }} style={{ width: 20, height: 20 }} /> : item.iconLink ? <Image source={{ uri: item.iconLink }} style={{ width: 20, height: 20 }} /> : <></>}
                                {!isSideBarCollapsed && <Text style={[styles.MenuText]}>{item.label}</Text>}
                            </TouchableOpacity>
                    }
                </View>}
            >
                {item.children && item.children.map((child, childIndex) => {
                    return <View style={{ paddingLeft: 20 }} key={childIndex.toString()}>
                        {/* <TouchableOpacity
                            onPress={() => {
                                navigationFn(child.link, child.params);
                            }}
                            key={childIndex.toString()} style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 0, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            <Text style={{ flex: 1 }}>{child.label}</Text>
                        </TouchableOpacity> */}
                        {Platform.OS === 'web' ? <Link key={childIndex.toString()} to={child.link}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 0, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                                <Text style={{ flex: 1 }}>{child.label}</Text>
                            </View>
                        </Link> : <TouchableOpacity
                            onPress={() => {
                                navigationFn(child.link, child.params);
                            }}
                            key={childIndex.toString()} style={{ flexDirection: 'row', alignItems: 'center', padding: 10, marginLeft: 0, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            <Text style={{ flex: 1 }}>{child.label}</Text>
                        </TouchableOpacity>}
                    </View>
                })}

                {item.custom && <item.custom />}
            </ExpandableSection>
        })
    }

    const mainHeadings = (name) => {
        return <View row flex centerV spread>
            <Text tabHeading>{name}</Text>
            <Icon name={showHRMS ? 'chevron-up' : 'chevron-down'} width={16} height={16} fill='#979899' />
        </View>;
    }

    return (<View
        useSafeArea
        style={{
            // height: Platform.OS == 'web' ? '90%' : Dimensions.get('window').height,
            flex: 1,
        }}>
        <ScrollView
            style={{
                // backgroundColor: 'pink',
                // height: 300,
                // maxHeight: Dimensions.get('screen').height - (40 + (StatusBar.currentHeight || 20)),
            }}
        >
            <View row={getSideBarPosition == 'top'}>
                <View>
                    <TouchableOpacity
                        onPress={() => {
                            navigationFn("dashboard", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-house.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                            {!isSideBarCollapsed && <Text tabHeading style={{ flex: 1 }}>Home</Text>}
                        </View>
                    </TouchableOpacity>
                </View>

                {/* <View>
                    <TouchableOpacity
                        onPress={() => {
                            navigationFn("dashboard", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-bell.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                            {!isSideBarCollapsed && <Text tabHeading style={{ flex: 1 }}>Notifications</Text>}
                        </View>
                    </TouchableOpacity>
                </View> */}

                {/* <ExpandableSection
                    expanded={showGYM}
                    onPress={() => {
                        setShowGYM(!showGYM);
                        setShowHRMS(false);
                        setShowAccounting(false);
                        setShowTasks(false);
                    }}
                    sectionHeader={<>
                        <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                        <View row centerV padding-10 style={showHRMS ? { backgroundColor: '#f7f7f7' } : {}}>
                            <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-HR.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                            {!isSideBarCollapsed && mainHeadings("GYM Management")}
                        </View>
                    </>}
                >
                    <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                        {renderMenu(gymMenu)}
                    </View>
                </ExpandableSection> */}

                {customSidebarMenu.length > 0 ? <>
                    {renderMenu(customSidebarMenu, "HRMS")}
                </> : <>
                    {approvalsMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showApprovals}
                        onPress={() => {
                            if (!showApprovals) {
                                setCurrentTab("Approvals");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowApprovals(!showApprovals);
                            // setShowGYM(false);
                            // setShowHRMS(false);
                            // setShowAccounting(false);
                            // setShowTasks(false);
                            // setShowMasters(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showTasks ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/Project.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("Approvals")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(approvalsMenu, "Approvals")}
                        </View>
                    </ExpandableSection>}

                    {hrmsMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showHRMS}
                        onPress={() => {
                            if (!showHRMS) {
                                setCurrentTab("HRMS");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowHRMS(!showHRMS);
                            // setShowGYM(false);
                            // setShowAccounting(false);
                            // setShowTasks(false);
                            // setShowMasters(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showHRMS ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/3d-HR.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("HRMS")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(hrmsMenu, "HRMS")}
                        </View>
                    </ExpandableSection>}

                    {accountingMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showAccounting}
                        onPress={() => {
                            if (!showAccounting) {
                                setCurrentTab("Accounting");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowAccounting(!showAccounting);
                            // setShowGYM(false);
                            // setShowHRMS(false);
                            // setShowTasks(false);
                            // setShowMasters(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showAccounting ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/AccountingMain.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("Accounting")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(accountingMenu, "Accounting")}
                        </View>
                    </ExpandableSection>}

                    {taskMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showTasks}
                        onPress={() => {
                            if (!showTasks) {
                                setCurrentTab("Tasks");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowTasks(!showTasks);
                            // setShowGYM(false);
                            // setShowHRMS(false);
                            // setShowAccounting(false);
                            // setShowMasters(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showTasks ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/Project.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("Projects & Tasks")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(taskMenu, "Projects & Tasks")}
                            <ProjectsComponent />
                        </View>
                    </ExpandableSection>}

                    {masterMenu.length > 0 && <ExpandableSection
                        expanded={isSideBarCollapsed ? false : showMasters}
                        onPress={() => {
                            if (!showMasters) {
                                setCurrentTab("Masters");
                            } else {
                                setCurrentTab("none");
                            }
                            setShowMasters(!showMasters);
                            // setShowGYM(false);
                            // setShowHRMS(false);
                            // setShowAccounting(false);
                            // setShowTasks(false);
                        }}
                        sectionHeader={<>
                            <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                            <View row centerV padding-10 style={showAccounting ? { backgroundColor: '#f7f7f7' } : {}}>
                                <Image source={{ uri: `https://inkapps.pages.dev/icons/Master.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                                {!isSideBarCollapsed && mainHeadings("Company Master")}
                            </View>
                        </>}
                    >
                        <View row={getSideBarPosition == 'top'} style={{ marginLeft: 25, borderLeftColor: 'rgb(232, 232, 232)', borderLeftWidth: 1 }}>
                            {renderMenu(masterMenu, "Company Master")}
                        </View>
                    </ExpandableSection>}
                </>}
            </View>
        </ScrollView>
        <View row spread style={{
            position: 'relative',
            // flex: 1,
            height: 40,
            maxHeight: 40,
            marginBottom: Platform.OS == 'android' ? 10 : 0,
        }}>
            <View>
                <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                <TouchableOpacity
                    onPress={async () => {
                        if (Platform.OS != 'web') {
                            await AsyncStorage.removeItem('token');
                            await AsyncStorage.removeItem('globalData');
                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('globalData');
                        }

                        setToken(null);
                        navigationFn("login", {});
                    }}
                    style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                    <View row>
                        <Image source={{ uri: `https://inkapps.pages.dev/icons/logout.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                    </View>
                </TouchableOpacity>
            </View>
            <View flex right>
                <View style={{ width: '100%', height: 1.5, backgroundColor: '#e5e5e561' }}></View>
                <View>
                    {employee?.name == 'SYSTEM ADMIN' && <TouchableOpacity
                        onPress={() => {
                            navigationFn("OrganizationSettings", {});
                        }}
                        style={{ flexDirection: 'row', alignItems: 'center', padding: 10 }}>
                        <View row centerV>
                            {!isSideBarCollapsed && <Text tabHeading style={{ flex: 1 }}>Organization Settings</Text>}

                            <Image source={{ uri: `https://inkapps.pages.dev/icons/org_settings.png` }} style={{ width: 20, height: 20, marginLeft: 10, marginRight: 8 }} />
                        </View>
                    </TouchableOpacity>}
                </View>
            </View>
        </View>
    </View>);
})

const styles = StyleSheet.create({
    MenuText: { marginLeft: 8, fontSize: 14, color: '#415669', letterSpacing: 0.6 },
    MenuHead: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        marginRight: 15,
        marginTop: 5,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        paddingVertical: 8,
    },
    highlightMenuItem: {
        backgroundColor: 'rgb(247, 247, 247)',
    },
});